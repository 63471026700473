<script setup>
    // ----------------- //
    // ---- IMPORTS ---- //
    // ----------------- //
    import { inject, watch, ref, onMounted, computed } from "vue";

    import { InjectionKeys } from 'o365-utils';

    import { $t } from 'o365-utils';

    // ------------------- //
    // ---- VARIABLES ---- //
    // ------------------- //
    const { pwaStoreKey } = InjectionKeys;

    const { state } = inject(pwaStoreKey);

    const syncDialog = ref(null);
    const syncDialogModal = ref(null);

    const secondsAfterSyncComplete = ref(0);
    const syncComplete = ref(false);

    // ------------------------- //
    // ---- LIFECYCLE HOOKS ---- //
    // ------------------------- //
    onMounted(() => {
        syncDialogModal.value = new bootstrap.Modal(syncDialog.value, {
            backdrop: 'static',
            keyboard: false
        });
    });

    // ------------------ //
    // ---- WATCHERS ---- //
    // ------------------ //
    watch(
        () => state?.syncInOtherContext,
        (value, oldValue) => {
            if (value === oldValue) {
                return;
            }

            if (value) {
                syncComplete.value = false;
                syncDialogModal.value.show();
            } else {
                secondsAfterSyncComplete.value = 0;
                syncComplete.value = true;

                const intervalId = setInterval(() => {
                    secondsAfterSyncComplete.value++;

                    if (secondsAfterSyncComplete.value > 5) {
                        clearInterval(intervalId);

                        window.location.reload();
                    }
                }, 1000);
            }
        }
    );

    // -------------------------- //
    // ---- COMPUTED METHODS ---- //
    // -------------------------- //
    const secondsLeft = computed(() => {
        return 5 - Math.min(secondsAfterSyncComplete.value, 5);
    });

    const showSecondsLeft = computed(() => {
        return syncComplete.value;
    });
</script>

<template>
    <teleport to="body">
        <div ref="syncDialog" class="modal o365-sync-dialog o365-sync-in-other-context" tabindex="-1">
            <div class="modal-dialog modal-dialog-scrollable">
                <div class="modal-content">
                    <div class="modal-header">
                        <div class="d-flex flex-column align-item-center text-center w-100">
                            <h5 class="modal-title">{{$t('Sync is currently running in another tab or window')}}</h5>
                            <p v-if="showSecondsLeft">{{$t('Sync is complete and this tab will reloaded in')}} {{secondsLeft}} {{$t('seconds')}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </teleport>
</template>
